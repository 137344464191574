/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { StaticQuestionsState } from "./types";

export const initialState: StaticQuestionsState = {
  staticQuestionFRD: [],
  staticQuestionStateLinkIds: {},
  jsonHierarchyModel: [],
};
