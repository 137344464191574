/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { Component, ReactElement } from "react";
import ReactPaginate from "react-paginate";
import "./companyQuestion.css";
import { Form, Table } from "react-bootstrap";

import { RatingUnit } from "../../types/customTypes/ratModels";

interface IProps {
  companies: RatingUnit[];
  onClick: ((unit: RatingUnit) => void) | undefined;
  selectedRatingUnitId: string | string[] | undefined;
  showProvisioningInfo: boolean;
  showProvisionedCheckbox: boolean;
  onCheck: ((unit: RatingUnit, allUnits: RatingUnit[]) => void) | undefined;
}

interface IState {
  currentPage: number;
  pageSize: number;
  numPages: number;
  pagedCompanies: RatingUnit[];
}

export default class CompanyView extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 20,
      numPages: 1,
      pagedCompanies: [],
    };
  }

  handlePageSelect = (selectedPage: { selected: number }): void => {
    this.setState({
      currentPage: selectedPage.selected + 1,
    });
  };

  componentDidMount = (): void => {
    this.getNumPages();
  };

  componentDidUpdate = (): void => {
    this.getNumPages();
  };

  getNumPages = (): void => {
    const numPages = Math.ceil(
      this.props.companies.length / this.state.pageSize
    );
    if (this.state.numPages !== numPages) {
      this.setState({
        numPages: numPages,
      });
    }
  };

  getPagedUnits = (): RatingUnit[] => {
    let pagedCompanies: RatingUnit[];
    if (this.props.companies.length > 1) {
      pagedCompanies = this.props.companies?.sort((unit1, unit2) => {
        if (
          this.props.showProvisionedCheckbox &&
          this.props.selectedRatingUnitId != null &&
          Array.isArray(this.props.selectedRatingUnitId) &&
          unit1.id != null &&
          unit2.id != null
        ) {
          const puIds = this.props.selectedRatingUnitId;
          if (puIds.includes(unit2.id) && !puIds.includes(unit1.id)) {
            return 1;
          } else if (puIds.includes(unit1.id) && !puIds.includes(unit2.id)) {
            return -1;
          }
        }
        if (unit1.name == null && unit2.name == null) return 0;
        else if (unit1.name == null) return -1;
        else if (unit2.name == null) return 1;
        else if (unit1.name < unit2.name) return -1;
        else if (unit1.name > unit2.name) return 1;
        return 0;
      });
      const spliceStart: number =
        (this.state.currentPage - 1) * this.state.pageSize;
      const spliceEnd: number =
        this.state.currentPage !== this.state.numPages
          ? spliceStart + this.state.pageSize
          : pagedCompanies.length;
      return pagedCompanies.slice(spliceStart, spliceEnd);
    } else {
      pagedCompanies = this.props.companies;
      return pagedCompanies;
    }
  };

  render = (): ReactElement => {
    const pagedCompanies = this.getPagedUnits();
    const clickFunction = this.props.onClick;
    const checkFunction = this.props.onCheck;
    return (
      <div>
        <Table className="company-view-table" striped>
          <thead>
            {this.props.showProvisioningInfo ? (
              this.props.showProvisionedCheckbox ? (
                <tr>
                  <th>Provisioned</th>
                  <th>Name</th>
                  <th>State</th>
                  <th>Status</th>
                  <th>Company ID</th>
                  <th>Plan ID</th>
                  <th>LOB</th>
                </tr>
              ) : (
                <tr>
                  <th>Name</th>
                  <th>State</th>
                  <th>Company ID</th>
                  <th>Plan ID</th>
                  <th>LOB</th>
                </tr>
              )
            ) : (
              <tr>
                <th>Name</th>
                <th>State</th>
              </tr>
            )}
          </thead>
          <tbody>
            {pagedCompanies.map((unit) => {
              return this.props.showProvisioningInfo ? (
                this.props.showProvisionedCheckbox ? (
                  <tr
                    key={unit.id ?? null}
                    onChange={
                      clickFunction != null
                        ? () => clickFunction(unit)
                        : (undefined as any)
                    }
                  >
                    <td>
                      <Form.Check
                        checked={
                          (unit.id != null &&
                            Array.isArray(this.props.selectedRatingUnitId) &&
                            this.props.selectedRatingUnitId.includes(
                              unit.id
                            )) ||
                          unit.id === this.props.selectedRatingUnitId
                        }
                        onChange={
                          checkFunction != null
                            ? () => checkFunction(unit, this.props.companies)
                            : (undefined as any)
                        }
                      />
                    </td>
                    <td>{unit.name}</td>
                    <td>{unit.state}</td>
                    <td>{unit.status}</td>
                    <td>{unit.companyId}</td>
                    <td>{unit.planId}</td>
                    <td>{unit.lineOfBusiness}</td>
                  </tr>
                ) : (
                  <tr>
                    {" "}
                    <td>{unit.name}</td>
                    <td>{unit.state}</td>
                    <td>{unit.companyId}</td>
                    <td>{unit.planId}</td>
                    <td>{unit.lineOfBusiness}</td>
                  </tr>
                )
              ) : (
                <tr
                  key={unit.id ?? null}
                  className={
                    unit.id != null &&
                    Array.isArray(this.props.selectedRatingUnitId) &&
                    this.props.selectedRatingUnitId.includes(unit.id)
                      ? "active"
                      : unit.id === this.props.selectedRatingUnitId
                      ? "active"
                      : "selectable"
                  }
                  onClick={
                    clickFunction != null
                      ? () => clickFunction(unit)
                      : (undefined as any)
                  }
                >
                  <td>{unit.name}</td>
                  <td>{unit.state}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {/* Page select */}
        {this.state.numPages > 1 && (
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={this.state.numPages}
            onPageChange={this.handlePageSelect}
            previousLinkClassName={"page-item page-link text-orng"}
            nextLinkClassName={"page-item page-link text-orng"}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link text-orng"}
            disabledClassName={"disabled"}
            activeClassName={"active"}
            activeLinkClassName={"active-page"}
            pageRangeDisplayed={4}
            marginPagesDisplayed={1}
          />
        )}
      </div>
    );
  };
}
