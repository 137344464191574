/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */

import { MigrationPacket } from "../../../types/customTypes/ratModels";

export enum ActionTypesEnum {
  SET_MIGRATION_PACKET = "SET_MIGRATION_PACKET",
  ADD_MIGRATION_PACKET = "ADD_MIGRATION_PACKET",
  UPDATE_MIGRATION_PACKET = "UPDATE_MIGRATION_PACKET",
  REMOVE_EDITED_MIGRATION_PACKET = "REMOVE_EDITED_MIGRATION_PACKET",
}

export interface RatingDataPacketState {
  packets: MigrationPacket[];
  editedPackets: MigrationPacket[];
}

export interface SetMigrationPacketAction {
  type: typeof ActionTypesEnum.SET_MIGRATION_PACKET;
  payload: MigrationPacket[];
}

export interface AddMigrationPacketAction {
  type: typeof ActionTypesEnum.ADD_MIGRATION_PACKET;
  payload: MigrationPacket;
}

export interface UpdateMigrationPacketAction {
  type: typeof ActionTypesEnum.UPDATE_MIGRATION_PACKET;
  payload: MigrationPacket[];
}

export interface RemoveEditedMigrationPacketAction {
  type: typeof ActionTypesEnum.REMOVE_EDITED_MIGRATION_PACKET;
  payload: MigrationPacket[];
}

export type RatingDataPacketActionTypes =
  | SetMigrationPacketAction
  | AddMigrationPacketAction
  | UpdateMigrationPacketAction
  | RemoveEditedMigrationPacketAction;
