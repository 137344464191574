/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { handleResponse, handleError, APIReturnTypes } from "./utils";

const baseUrl = "api/FlatRatingData/";

export const getFlatRatingDataByRatingDataId = async (
  ratingDataId: string
): Promise<APIReturnTypes> => {
  return await fetch(baseUrl + "GetFlatRatingDataById?id=" + ratingDataId)
    .then(handleResponse)
    .catch(handleError);
};

export const getFlatRatingDataByRatingUnitId = async (
  ratingUnitId: number,
  includeStaticQsts: boolean
): Promise<APIReturnTypes> => {
  return await fetch(
    baseUrl +
      "GetFlatRatingDataByRatingUnitId?id=" +
      ratingUnitId.toString() +
      "&includeStaticQuestions=" +
      (includeStaticQsts ? "true" : "false")
  )
    .then(handleResponse)
    .catch(handleError);
};

export const getFlatRatingDataRequiredStaticQuestions = async (
  ratingUnitId: number
): Promise<APIReturnTypes> => {
  return await fetch(
    baseUrl +
      "GetRequiredStaticQuestionsByRatingUnitId?id=" +
      ratingUnitId.toString()
  )
    .then(handleResponse)
    .catch(handleError);
};

export const getRatingDataPacketByRatingDataId = async (
  ratingDataId: string
): Promise<APIReturnTypes> => {
  return await fetch(
    baseUrl + "GetPacketByRatingDataId?ratingDataId=" + ratingDataId
  )
    .then(handleResponse)
    .catch(handleError);
};

export const getRatingDataPacketByPropertyNameTypeName = async (
  propertyName: string,
  typeName: string
): Promise<APIReturnTypes> => {
  return await fetch(
    baseUrl +
      "GetPacketByPropertyNameTypeName?property=" +
      propertyName +
      "&type=" +
      typeName
  )
    .then(handleResponse)
    .catch(handleError);
};
