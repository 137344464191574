/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { ExpressionV1 } from "../../../types/customTypes/ratModels";

type RatingDataSampleV1 = RatingDataSampleResponseV1;

export enum ActionTypesEnum {
  RATING_DATA_SAMPLE = "RATING_DATA_SAMPLE",
}

export interface RatingDataSampleState {
  ratingDataSamples: RatingDataSampleV1[];
}

interface SetRatingDataSampleAction {
  type: typeof ActionTypesEnum.RATING_DATA_SAMPLE;
  payload: RatingDataSampleV1[];
}

// TODO: Remove and use when rate sample API developed - US88446
export interface RatingDataSampleResponseV1 {
  id?: string;
  /** Process sequence governing rule execution within a rating data, as rating data might contain multiple rules. */
  processSequence?: number;
  /** Expression describing business rules for the rating data. */
  expression: ExpressionV1;
  /** Rule formula representation. */
  formula?: string;
}

export type RatingDataSampleActionTypes = SetRatingDataSampleAction;
