/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
export enum Pages {
  LOGIN = "LOGIN",
  REFERENCE = "REFERENCE",
  PROVISIONING = "PROVISIONING",
  MIGRATION = "MIGRATION",
  ARTIFACTS = "ARTIFACTS",
  UNKNOWN = "UNKNOWN",
}
