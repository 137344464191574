/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { MigrationPacket } from "../../../types/customTypes/ratModels";
import { ActionTypesEnum, RatingDataPacketActionTypes } from "./types";

export function SetMigrationPacketAction(
  packets: MigrationPacket[]
): RatingDataPacketActionTypes {
  return {
    type: ActionTypesEnum.SET_MIGRATION_PACKET,
    payload: packets,
  };
}

export function AddMigrationPacketAction(
  packet: MigrationPacket
): RatingDataPacketActionTypes {
  return {
    type: ActionTypesEnum.ADD_MIGRATION_PACKET,
    payload: packet,
  };
}

export function UpdateMigrationPacketAction(
  packets: MigrationPacket[]
): RatingDataPacketActionTypes {
  return {
    type: ActionTypesEnum.UPDATE_MIGRATION_PACKET,
    payload: packets,
  };
}

export function RemoveEditedMigrationPacketAction(): RatingDataPacketActionTypes {
  return {
    type: ActionTypesEnum.REMOVE_EDITED_MIGRATION_PACKET,
    payload: [],
  };
}
