/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import * as flatRatingDataApi from "../../api/flatRatingData";
import { Dispatch } from "react";
import {
  AddMigrationPacketAction,
  RemoveEditedMigrationPacketAction,
  UpdateMigrationPacketAction,
} from "./action";
import { RatingDataPacketActionTypes } from "./types";
import { isTypeOf } from "../../../util/typeGuards";
import { MigrationPacket } from "../../../types/customTypes/ratModels";

export const getPacketByRatingDataIdController = (ratingDataId: string) => {
  return async (dispatch: Dispatch<RatingDataPacketActionTypes>) => {
    return await flatRatingDataApi
      .getRatingDataPacketByRatingDataId(ratingDataId)
      .then((packet) => {
        if (
          !isTypeOf<MigrationPacket>(packet, [
            "ratingData",
            "apiPath",
            "states",
          ])
        ) {
          throw new Error("Error communicating with API");
        }
        dispatch(AddMigrationPacketAction(packet));
      });
  };
};

export const getPacketByTypeNamePropertyNameController = (
  propertyName: string,
  typeName: string
) => {
  return async (dispatch: Dispatch<RatingDataPacketActionTypes>) => {
    return await flatRatingDataApi
      .getRatingDataPacketByPropertyNameTypeName(propertyName, typeName)
      .then((packet) => {
        if (
          !isTypeOf<MigrationPacket>(packet, [
            "ratingData",
            "apiPath",
            "states",
          ])
        ) {
          throw new Error("Error communicating with API");
        }
        dispatch(AddMigrationPacketAction(packet));
      });
  };
};

export const updatePacketController = (migrationPacket: MigrationPacket) => {
  return async (
    dispatch: Dispatch<RatingDataPacketActionTypes>
  ): Promise<void> => {
    const editedPackets: [MigrationPacket] = [migrationPacket];
    dispatch(UpdateMigrationPacketAction(editedPackets as MigrationPacket[]));
  };
};

export const removeEditedPacketsController = () => {
  return async (
    dispatch: Dispatch<RatingDataPacketActionTypes>
  ): Promise<void> => {
    dispatch(RemoveEditedMigrationPacketAction());
  };
};
