/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { EntitlementLevel } from "../../../types/enums/entitlementLevel";

export enum ActionTypesEnum {
  SET_USER_NAME = "SET_USER_NAME",
  SET_ENTITLEMENT_LEVEL = "SET_ENTITLEMENT_LEVEL",
}

export interface UserAuthState {
  userName: string | undefined;
  userEntitlement: EntitlementLevel;
}

interface SetUserNameAction {
  type: typeof ActionTypesEnum.SET_USER_NAME;
  payload: string | undefined;
}

interface SetEntitlementLevelAction {
  type: typeof ActionTypesEnum.SET_ENTITLEMENT_LEVEL;
  payload: EntitlementLevel;
}

export type UserAuthActionTypes = SetUserNameAction | SetEntitlementLevelAction;
