/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
/* eslint-disable spaced-comment */
import * as userAuthApi from "../../api/auth";
import { isTypeOf } from "../../../util/typeGuards";
import { setEntitlementLevel, setUserName } from "./action";
import { EntitlementLevel } from "../../../types/enums/entitlementLevel";
import { Dispatch } from "react";
import { UserAuthActionTypes } from "./types";
import { reset } from "../rootActions";
import { RootActionTypes } from "../rootType";
import { isDev } from "../../../util/helperFunctions";
import { AuthResponse } from "../../../types/customTypes/ratModels";

export const signInController = (username: string, password: string) => {
  return async (dispatch: Dispatch<UserAuthActionTypes>): Promise<void> => {
    return await userAuthApi
      .signIn({ UserName: username, Password: password })
      .then((authResponse) => {
        if (!isTypeOf<AuthResponse>(authResponse, ["status", "message"])) {
          throw new Error("Error communicating with API");
        }
        dispatch(setUserName(username));
      });
    /* .catch((error) => {
        if (isDev()) {
          dispatch(setUserName(username));
        } else {
          throw error;
        }
      });*/
  };
};

export const getEntitlementLevelController = () => {
  return async (dispatch: Dispatch<UserAuthActionTypes>): Promise<void> => {
    return await userAuthApi
      .getAccessLevel()
      .then((entitlementString) => {
        if ((entitlementString as string) in EntitlementLevel) {
          const entitlementEnum = entitlementString as EntitlementLevel;
          if (entitlementEnum === EntitlementLevel.NO_ACCESS) {
            throw new Error("User not authorized to access");
          }
          dispatch(setEntitlementLevel(entitlementEnum));
        } else {
          throw new Error("Error communicating with API");
        }
      })
      .catch((error) => {
        if (isDev()) {
          dispatch(setEntitlementLevel(EntitlementLevel.READ_AND_WRITE_ACCESS));
        } else {
          throw error;
        }
      });
  };
};

export const logoutController = () => {
  return async (
    dispatch: Dispatch<UserAuthActionTypes | RootActionTypes>
  ): Promise<void> => {
    return await userAuthApi
      .signOut()
      .then(() => {
        dispatch(reset());
        dispatch(setEntitlementLevel(EntitlementLevel.NO_ACCESS));
      })
      .catch(() => {
        dispatch(reset());
        dispatch(setEntitlementLevel(EntitlementLevel.NO_ACCESS));
      });
  };
};
