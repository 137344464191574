/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { definitions as authDefinitions } from "../../types/swagger/authServiceTypings";
import { TenantInfo } from "../store/provisioning/types";
import {
  AuthResponse,
  FlatRatingData,
  FlatRatingDataV1,
  MigrationPacket,
  MigrationPacketV1,
  RuleV1,
  ValueListV1,
  RatingUnitV1,
  JsonHierarchy,
} from "../../types/customTypes/ratModels";

type OAuthTokenV1 = authDefinitions["OAuthTokenV1"];

export type APIReturnTypes =
  | string
  | FlatRatingDataV1[]
  | OAuthTokenV1
  | RuleV1[]
  | ValueListV1[]
  | ValueListV1
  | RatingUnitV1[]
  | FlatRatingDataV1
  | FlatRatingData
  | string[]
  | TenantInfo
  | AuthResponse
  | JsonHierarchy[]
  | MigrationPacketV1[]
  | MigrationPacket
  | MigrationPacket[];

export async function handleResponse(
  response: Response,
  returnJson = true
): Promise<APIReturnTypes> {
  if (!response.ok) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }
  if (response.status === 204 || !returnJson) return await response.text();
  if (response.ok) return await response.json();
  console.error(response.text());
  throw new Error("Network response was not ok.");
}

// In a real app, would likely call an error logging service.
export function handleError(error: Error): never {
  // eslint-disable-next-line no-console
  console.error("API call failed. " + error.message);
  throw error;
}

export async function handleResponseArtifact(
  response: Response,
  returnJson = true
): Promise<APIReturnTypes> {
  console.log(response.status, "error artifact value");

  if (response.status === 204 || !returnJson) return await response.text();
  if (response.ok) return await response.json();
  console.error(response.text());
  if (response.status === 500) {
    console.log("error artifact");
    console.log(response.status, "error artifact value");
    return [];
  }
  throw new Error("Network response was not ok.");
}
