/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { TenantInfoV1 } from "../../../types/customTypes/ratModels";

export enum ActionTypesEnum {
  GET_ALL_TENANTS = "GET_ALL_TENANTS",
  ADD_NEW_TENANT = "ADD_NEW_TENANTS",
}

export interface ProvisioningState {
  allTenantInfo: TenantInfo[];
}

export interface GetTenantInfoAction {
  type: typeof ActionTypesEnum.GET_ALL_TENANTS;
  payload: TenantInfo[];
}

export interface AddTenantInfoAction {
  type: typeof ActionTypesEnum.ADD_NEW_TENANT;
  payload: TenantInfo;
}

export type ProvisioningActionTypes = GetTenantInfoAction | AddTenantInfoAction;
export type TenantInfo = TenantInfoV1;
