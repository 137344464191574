/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { APIReturnTypes, handleError, handleResponse } from "./utils";

const basePath = "api/RatingUnit";

export const getRatingUnits = async (): Promise<APIReturnTypes> => {
  return await fetch(basePath + "/GetAllRatingUnits")
    .then(handleResponse)
    .catch(handleError);
};

export const getProvisionedUnitsByTenant = async (
  tenantId: string
): Promise<APIReturnTypes> => {
  return await fetch(basePath + "/GetProvisionedUnits?tenantId=" + tenantId)
    .then(handleResponse)
    .catch(handleError);
};
