/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { handleResponse, handleError, APIReturnTypes } from "./utils";

const baseUrl = "api/TenantInfo/";

export const getAllTenantInfo = async (): Promise<APIReturnTypes> => {
  return await fetch(baseUrl + "GetAllTenantInfo")
    .then(handleResponse)
    .catch(handleError);
};

export const saveTenant = async (
  tenantId: string,
  tenantName: string,
  ratingAgencyId: number
): Promise<APIReturnTypes> => {
  return await fetch(baseUrl + "AddOrUpdateTenant", {
    headers: {
      "Content-Type": "application/json",
    },
    method: "PUT",
    body: JSON.stringify({
      tenantId: tenantId,
      tenantName: tenantName,
      ratingAgencyId: ratingAgencyId,
    }),
  })
    .then(handleResponse)
    .catch(handleError);
};
