/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { Component, ReactElement } from "react";

class MigrationComponent extends Component {
  render = (): ReactElement => {
    return <h1>Migration</h1>;
  };
}

export default MigrationComponent;
