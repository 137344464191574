/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import {
  ActionTypesEnum,
  RatingArtifactState,
  RatingArtifactsActionTypes,
  RatingArtifact,
} from "./types";
import { initialState } from "./initialState";

const ratingArtifactsCopy = (
  state: RatingArtifactState
): { [correlationId: string]: RatingArtifact[] } => {
  const newRatingArtifacts: { [correlationId: string]: RatingArtifact[] } = {};
  Object.keys(state.ratingArtifacts).forEach((correlationId) => {
    newRatingArtifacts[correlationId] = [
      ...state.ratingArtifacts[correlationId],
    ];
  });
  return newRatingArtifacts;
};

export const ratingArtifactReducer = (
  // Redux requires state to be the first parameter
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: RatingArtifactsActionTypes
): RatingArtifactState => {
  switch (action.type) {
    case ActionTypesEnum.GET_ARTIFACTS_BY_CID: {
      const newRatingArtifacts = ratingArtifactsCopy(state);
      newRatingArtifacts[action.payload.correlationId] = [
        ...action.payload.ratingArtifacts,
      ];
      return {
        ...state,
        ratingArtifacts: newRatingArtifacts,
      };
    }
    default:
      return state;
  }
};
