/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { Component, ReactElement } from "react";
import Spinner from "react-bootstrap/Spinner";
import { InitialStateType } from "../../app/store/rootType";
import { connect, ConnectedProps } from "react-redux";
import { getAllStaticQuestionsController } from "../../app/store/staticQuestions/controller";
import { addRequiredStaticQuestionsByRatingUnitController } from "../../app/store/companyQuestions/controller";
import { Card, ListGroup } from "react-bootstrap";
import "./ratingUnitRatingData.css";
interface RequiredStaticQstProps {
  ratingUnitId: number;
  lineOfBusiness: string;
}

interface RequiredStaticQstsState {
  requiredStaticQuestionPaths: string[];
  loading: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: InitialStateType) => ({
  staticQuestionFRD: state.staticQuestions.staticQuestionFRD,
  requiredStaticQuestions: state.companyQuestions.requiredStaticQuestionFRD,
});

const mapDispatchToProps = {
  getAllStaticQuestionsController,
  addRequiredStaticQuestionsByRatingUnitController,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & RequiredStaticQstProps;

class RequiredStaticQuestionsComponent extends Component<
  Props,
  RequiredStaticQstsState
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      requiredStaticQuestionPaths: [],
      loading: false,
    };
  }

  componentDidMount = async (): Promise<void> => {
    await this.loadRequiredStaticQuestions();
  };

  componentDidUpdate = async (
    prevProps: RequiredStaticQstProps
  ): Promise<void> => {
    if (
      prevProps.ratingUnitId !== this.props.ratingUnitId ||
      prevProps.lineOfBusiness !== this.props.lineOfBusiness
    ) {
      await this.loadRequiredStaticQuestions();
    }
  };

  loadRequiredStaticQuestions = async (): Promise<void> => {
    this.setState({
      loading: true,
    });

    // First, let's make sure SQ is populated. It should be, but if not we need to grab it.
    if (this.props.staticQuestionFRD.length <= 0) {
      await this.props.getAllStaticQuestionsController();
    }
    // Now, pull required SQs
    await this.props.addRequiredStaticQuestionsByRatingUnitController(
      this.props.ratingUnitId
    );

    this.getRequiredStaticQuestionPaths();
  };

  getRequiredStaticQuestionPaths = (): void => {
    const requiredSQFRD = this.props.requiredStaticQuestions.filter(
      (x) => x.ratingUnitId === this.props.ratingUnitId && x.isRequired
    );
    const paths: string[] = [];

    requiredSQFRD.forEach((frd) => {
      if (frd.lineOfBusiness === this.props.lineOfBusiness) {
        // Start with the top level path/name
        let path = (frd.apiTypeName ?? "") + "/" + (frd.apiPropertyName ?? "");

        let parentId = frd.apiTypeParentId;
        while (parentId != null) {
          const parentFrd = this.props.staticQuestionFRD.find(
            (x) => x.apiTypeId === parentId
          );
          if (parentFrd == null) {
            break;
          }
          path = (parentFrd.apiTypeName ?? "") + "/" + path;
          parentId = parentFrd.apiTypeParentId;
        }
        paths.push(path);
      }
    });

    this.setState({
      requiredStaticQuestionPaths: paths,
      loading: false,
    });
  };

  render = (): ReactElement => {
    return (
      <div className="rurd-container">
        {this.state.loading ? (
          <div>
            {" "}
            <Spinner animation="border" className="loader" />
          </div>
        ) : (
          <div>
            <div className="rurd">
              {this.state.requiredStaticQuestionPaths.length === 0 ? (
                <p className="text-center mt-2 text-muted">
                  No required static questions to display
                </p>
              ) : (
                <Card>
                  <ListGroup variant="flush">
                    {this.state.requiredStaticQuestionPaths.map((path) => (
                      <ListGroup.Item
                        key={path}
                        className="listitems"
                        title="Indicates required for rating.  Can be overridden by rules."
                      >
                        {path}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Card>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };
}

export default connector(RequiredStaticQuestionsComponent);
