/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import * as staticQuestionsApi from "../../api/staticQuestions";
import { Dispatch } from "react";
import { isTypeOf } from "../../../util/typeGuards";
import { StaticQuestionActionTypes } from "./types";
import {
  setStaticQuestionsAction,
  setStaticQuestionStateLinkIdsAction,
  setJsonHierarchy,
} from "./actions";
import {
  FlatRatingDataV1,
  JsonHierarchy,
} from "../../../types/customTypes/ratModels";

export const getAllStaticQuestionsController = () => {
  return async (
    dispatch: Dispatch<StaticQuestionActionTypes>
  ): Promise<void> => {
    return await staticQuestionsApi
      .getAllStaticQuestions()
      .then((allStaticQuestion) => {
        if (!Array.isArray(allStaticQuestion)) {
          throw new Error("Error communicating with API");
        }
        if (
          allStaticQuestion.length > 0 &&
          !isTypeOf<FlatRatingDataV1>(allStaticQuestion[0], [
            "apiPropertyId",
            "apiTypeId",
          ])
        ) {
          throw new Error("Error communicating with API");
        }
        dispatch(
          setStaticQuestionsAction(allStaticQuestion as FlatRatingDataV1[])
        );
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getAllStaticQuestionStateLinkIdsController = (state: string) => {
  return async (
    dispatch: Dispatch<StaticQuestionActionTypes>
  ): Promise<void> => {
    return await staticQuestionsApi
      .getAllStaticQuestionStateLinks(state)
      .then((allStaticQuestionStateLinks) => {
        if (!Array.isArray(allStaticQuestionStateLinks)) {
          throw new Error("Error communicating with API");
        }
        dispatch(
          setStaticQuestionStateLinkIdsAction(
            state,
            allStaticQuestionStateLinks as string[]
          )
        );
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getHierarchyModelController = () => {
  return async (
    dispatch: Dispatch<StaticQuestionActionTypes>
  ): Promise<void> => {
    return await staticQuestionsApi
      .getHierarchyModel()
      .then((hierarcyModel) => {
        if (!Array.isArray(hierarcyModel)) {
          throw new Error("Error communicating with API");
        }
        dispatch(setJsonHierarchy(hierarcyModel as JsonHierarchy[]));
      })
      .catch((error) => {
        throw error;
      });
  };
};
