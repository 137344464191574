/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { MigrationPacket } from "../../../types/customTypes/ratModels";
import { initialState } from "./initialState";
import {
  ActionTypesEnum,
  RatingDataPacketActionTypes,
  RatingDataPacketState,
} from "./types";

export function ratingDataPacketReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: RatingDataPacketActionTypes
): RatingDataPacketState {
  switch (action.type) {
    case ActionTypesEnum.SET_MIGRATION_PACKET:
      return {
        ...state,
        packets: action.payload,
      };
    case ActionTypesEnum.ADD_MIGRATION_PACKET:
      return {
        ...state,
        packets: [...state.packets, action.payload],
      };
    case ActionTypesEnum.UPDATE_MIGRATION_PACKET: {
      const editedPacket = state.editedPackets.find(
        (x) => x.ratingData?.id === action.payload[0].ratingData?.id
      );
      if (editedPacket == null) {
        const newArr: MigrationPacket[] = [];
        // Only add if it hasn't been added already
        action.payload.forEach((x) => {
          newArr.push(x);
        });
        return {
          ...state,
          editedPackets: [...state.editedPackets, ...newArr],
        };
      } else {
        // if exists, update and return edited object
        return {
          ...state,
          editedPackets: state.editedPackets.map((ques) => {
            if (ques.ratingData?.id === action.payload[0].ratingData?.id) {
              return action.payload[0];
            }
            return ques;
          }),
        };
      }
    }
    case ActionTypesEnum.REMOVE_EDITED_MIGRATION_PACKET: {
      return {
        ...state,
        editedPackets: [],
      };
    }
    default:
      return state;
  }
}
