/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
export enum STATES {
  ALABAMA = "ALABAMA",
  ALASKA = "ALASKA",
  ARIZONA = "ARIZONA",
  ARKANSAS = "ARKANSAS",
  CALIFORNIA = "CALIFORNIA",
  COLORADO = "COLORADO",
  CONNECTICUT = "CONNECTICUT",
  DELAWARE = "DELAWARE",
  DISTRICT_OF_COLUMBIA = "DISTRICT_OF_COLUMBIA",
  FLORIDA = "FLORIDA",
  GEORGIA = "GEORGIA",
  IDAHO = "IDAHO",
  ILLINOIS = "ILLINOIS",
  INDIANA = "INDIANA",
  IOWA = "IOWA",
  KANSAS = "KANSAS",
  KENTUCKY = "KENTUCKY",
  LOUISIANA = "LOUISIANA",
  MAINE = "MAINE",
  MARYLAND = "MARYLAND",
  MASSACHUSETTS = "MASSACHUSETTS",
  MICHIGAN = "MICHIGAN",
  MINNESOTA = "MINNESOTA",
  MISSISSIPPI = "MISSISSIPPI",
  MISSOURI = "MISSOURI",
  MONTANA = "MONTANA",
  NEBRASKA = "NEBRASKA",
  NEVADA = "NEVADA",
  NEW_HAMPSHIRE = "NEW_HAMPSHIRE",
  NEW_JERSEY = "NEW_JERSEY",
  NEW_MEXICO = "NEW_MEXICO",
  NEW_YORK = "NEW_YORK",
  NORTH_CAROLINA = "NORTH_CAROLINA",
  NORTH_DAKOTA = "NORTH_DAKOTA",
  OHIO = "OHIO",
  OKLAHOMA = "OKLAHOMA",
  OREGON = "OREGON",
  PENNSYLVANIA = "PENNSYLVANIA",
  RHODE_ISLAND = "RHODE_ISLAND",
  SOUTH_CAROLINA = "SOUTH_CAROLINA",
  SOUTH_DAKOTA = "SOUTH_DAKOTA",
  TENNESSEE = "TENNESSEE",
  TEXAS = "TEXAS",
  UTAH = "UTAH",
  VERMONT = "VERMONT",
  VIRGINIA = "VIRGINIA",
  WASHINGTON = "WASHINGTON",
  WEST_VIRGINIA = "WEST_VIRGINIA",
  WISCONSIN = "WISCONSIN",
  WYOMING = "WYOMING",
}

export function getStateStringArray(): string[] {
  const stateList: string[] = [];
  Object.keys(STATES).forEach((key) => {
    // Typescript double maps enums, so that they can be accessed by both key and value
    if (!stateList.includes(key)) {
      stateList.push(key);
    }
  });
  return stateList;
}
