/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { Component, ReactElement } from "react";

class NotFoundPage extends Component {
  render = (): ReactElement => {
    return <h1>404 Page Not Found</h1>;
  };
}

export default NotFoundPage;
