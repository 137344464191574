/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { FlatRatingDataV1 } from "../../../types/customTypes/ratModels";

export enum ActionTypesEnum {
  ADD_COMPANY_QUESTION = "ADD_COMPANY_QUESTION",
  ADD_COMPANY_QUESTION_ARRAY = "ADD_COMPANY_QUESTION_ARRAY",
  ADD_REQUIRED_STATIC_QUESTION = "ADD_REQUIRED_STATIC_QUESTION",
  ADD_REQUIRED_STATIC_QUESTION_ARRAY = "ADD_REQUIRED_STATIC_QUESTION_ARRAY",
}

export interface CompanyQuestionState {
  companyQuestionFRD: FlatRatingDataV1[];
  requiredStaticQuestionFRD: FlatRatingDataV1[];
}

export interface AddCompanyQuestionAction {
  type: typeof ActionTypesEnum.ADD_COMPANY_QUESTION;
  payload: FlatRatingDataV1;
}

export interface AddCompanyQuestionArrayAction {
  type: typeof ActionTypesEnum.ADD_COMPANY_QUESTION_ARRAY;
  payload: FlatRatingDataV1[];
}
export interface AddRequiredStaticQuestionAction {
  type: typeof ActionTypesEnum.ADD_REQUIRED_STATIC_QUESTION;
  payload: FlatRatingDataV1;
}

export interface AddRequiredStaticQuestionArrayAction {
  type: typeof ActionTypesEnum.ADD_REQUIRED_STATIC_QUESTION_ARRAY;
  payload: FlatRatingDataV1[];
}

export type CompanyQuestionActionTypes =
  | AddCompanyQuestionAction
  | AddCompanyQuestionArrayAction
  | AddRequiredStaticQuestionAction
  | AddRequiredStaticQuestionArrayAction;
