/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { handleResponse, handleError, APIReturnTypes } from "./utils";

const baseUrl: string = "api/StaticQuestions/";

export const getAllStaticQuestions = async (): Promise<APIReturnTypes> => {
  return await fetch(baseUrl + "GetStaticQuestions")
    .then(handleResponse)
    .catch(handleError);
};

export const getAllStaticQuestionStateLinks = async (
  state: string
): Promise<APIReturnTypes> => {
  return await fetch(baseUrl + "GetStaticQuestionStateLinks?state=" + state)
    .then(handleResponse)
    .catch(handleError);
};

export const getRatingSampleData = async (
  ratingDataId: string
): Promise<APIReturnTypes> => {
  return await fetch(
    baseUrl + "GetRatingSampleListByRatingDataId?ratingDataId=" + ratingDataId
  )
    .then(handleResponse)
    .catch(handleError);
};

export const getHierarchyModel = async (): Promise<APIReturnTypes> => {
  return await fetch("api/FlatRatingData/GetHierarchyModel")
    .then(handleResponse)
    .catch(handleError);
};
