/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { handleResponseArtifact, handleError, APIReturnTypes } from "./utils";

const baseUrl = "api/Artifact/";

export const getRatingArtifactsByCorrelationId = async (
  tenantId: string,
  correlationId: string
): Promise<APIReturnTypes> => {
  return await fetch(
    baseUrl +
      "GetArtifactsByCorrelationId?correlationId=" +
      correlationId +
      "&tenantId=" +
      tenantId
  )
    .then(handleResponseArtifact)
    .catch(handleError);
};

export const downloadArtifacts = async (
  tenantId: string,
  correlationId: string
): Promise<void> => {
  return await fetch(
    baseUrl +
      "DownloadRatingArtifacts?correlationId=" +
      correlationId +
      "&tenantId=" +
      tenantId,
    {
      headers: {
        "Content-Type": "application/zip",
      },
    }
  )
    .then(async (response) => await response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", correlationId + ".zip");
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    })
    .catch(handleError);
};
