/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { Component, ReactElement } from "react";
import { Container, Col, Row } from "react-bootstrap";
import ProvisioningCompanySelectComponent from "../../components/provisioning/provisioningCompanySelect";
import TenantListComponent from "../../components/provisioning/tenantList";
import { TenantInfo } from "../../app/store/provisioning/types";

interface State {
  selectedTenant: TenantInfo | undefined; // placeholder
}

class ProvisioningComponent extends Component<unknown, State> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      selectedTenant: undefined,
    };
  }

  selectTenant = (tenant: TenantInfo | undefined): void => {
    this.setState({
      selectedTenant: tenant,
    });
  };

  render = (): ReactElement => {
    return (
      <div>
        <Container fluid>
          <Row>
            <Col>
              <TenantListComponent
                selectedTenant={this.state.selectedTenant}
                tenantClick={this.selectTenant}
              />
            </Col>
            <Col>
              {this.state.selectedTenant !== undefined ? (
                <ProvisioningCompanySelectComponent
                  selectedTenant={this.state.selectedTenant}
                />
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  };
}

export default ProvisioningComponent;
