/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { Component, ReactElement } from "react";
import {
  Accordion,
  Card,
  Button,
  ListGroup,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { isTypeOf } from "../../util/typeGuards";
import "./accordionTree.css";

export interface ApiPropertyNode {
  key: string;
  label: string;
  id: string;
  ratingDataId: string;
  tag: string;
  type: string;
  lineOfBusiness: string;
  annotation: string;
  isArray: boolean;
  apiPath: string;
}

export interface ApiTypeNode {
  key: string;
  label: string;
  id: string;
  type: string;
  show: boolean | undefined;
  nodes: Array<ApiTypeNode | ApiPropertyNode>;
  isVisible: boolean;
  sortOrder: number;
  annotation: string;
  isArray: boolean;
  apiPath: string;
}

interface AccordionTreeProps {
  parentNode: ApiTypeNode;
  activeRatingData: string | undefined;
  selectRatingData: (
    apiPropertyName: string,
    apiPropertyType: string,
    ratingDataId: string,
    lineOfBusiness: string,
    selectedNode: ApiTypeNode | ApiPropertyNode | undefined
  ) => void;

  showRightPanel: (clicked: boolean) => void;
}

export default class AccordionTree extends Component<AccordionTreeProps> {
  state = { active: true };
  render = (): ReactElement => {
    return (
      <Accordion
        // If show is defined, then it means the tree is supposed to be expanded for some nodes. If it's not defined, default un-expanded
        activeKey={
          this.props.parentNode.show != null && this.props.parentNode.show
            ? "0"
            : (undefined as unknown as string)
        }
      >
        <Card className="accCard">
          <Card.Header className="cardheader">
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="0"
              onClick={() => {
                this.state.active
                  ? this.setState({ active: false })
                  : this.setState({ active: true });
              }}
            >
              {this.state.active ? (
                <i className="fas fa-plus-circle" color="var(--orng)"></i>
              ) : (
                <i className="fas fa-minus-circle" color="var(--orng)"></i>
              )}
              <span
                className="cardheaderTxt"
                title={
                  this.props.parentNode.isArray
                    ? "Array of " +
                      this.props.parentNode.type +
                      "  " +
                      this.props.parentNode.annotation
                    : this.props.parentNode.type +
                      "  " +
                      this.props.parentNode.annotation
                }
              >
                {this.props.parentNode.label}
              </span>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <ListGroup>
              {this.props.parentNode.nodes.map((node) => {
                // For each node, if it's an API Type, render another Tree view. If it's a property, then just render than as a List Group Item
                if (isTypeOf<ApiTypeNode>(node, "nodes")) {
                  return (
                    node.isVisible && (
                      <Container key={node.id} title={node.annotation}>
                        <Row>
                          <Col md={{ offset: 1 }}>
                            <AccordionTree
                              parentNode={node}
                              selectRatingData={this.props.selectRatingData}
                              activeRatingData={this.props.activeRatingData}
                              showRightPanel={this.props.showRightPanel}
                            />
                          </Col>
                        </Row>
                      </Container>
                    )
                  );
                }
                return (
                  <Container key={node.id} title={node.annotation}>
                    <Row>
                      <Col md={{ offset: 1 }}>
                        <ListGroup.Item
                          onClick={() => {
                            this.props.selectRatingData(
                              node.label,
                              node.type,
                              node.ratingDataId,
                              node.lineOfBusiness,
                              node
                            );
                            this.props.showRightPanel(true);
                          }}
                          active={
                            this.props.activeRatingData !== undefined &&
                            this.props.activeRatingData !== "" &&
                            this.props.activeRatingData === node.ratingDataId
                          }
                          className={
                            node.ratingDataId === ""
                              ? "noRatingData"
                              : "hasRatingData"
                          }
                        >
                          {node.label}
                        </ListGroup.Item>
                      </Col>
                    </Row>
                  </Container>
                );
              })}
            </ListGroup>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  };
}
