/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { Component, ReactElement } from "react";
import { Redirect, Switch, Route } from "react-router";
import ApiReferenceComponent from "../features/apiReference/apiReference";
import loginPage from "../features/login/login";
import NotFoundPage from "../features/pageNotFound/pageNotFound";
import ProvisioningComponent from "../features/provisioning/provisioning";
import { EntitlementLevel } from "../types/enums/entitlementLevel";
import { Pages } from "../types/enums/pages";
// TODO User Entitlement
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ProtectedRoute from "./ProtectedRoute";
import Header from "../features/header/header";
import ArtifactComponent from "../features/artifact/artifact";
import MigrationComponent from "../features/migration/migration";
import { PropsFromRedux } from ".";
import CompanyQuestionPage from "../components/companyQuestions/companyQuestionPage";
export const RouteMap = new Map<Pages, string>([
  [Pages.LOGIN, "/login"],
  [Pages.ARTIFACTS, "/artifacts"],
  [Pages.MIGRATION, "/migration"],
  [Pages.PROVISIONING, "/provisioning"],
  [Pages.REFERENCE, "/reference"],
]);

export const getPagePath = (page: Pages): string => {
  const path = RouteMap.get(page);
  if (path === undefined) {
    return "/404";
  }
  return path;
};

type Props = PropsFromRedux;

class RouteComponent extends Component<Props> {
  getHasReadAccess = (): boolean => {
    return (
      this.props.userEntitlement === EntitlementLevel.READ_ACCESS ||
      this.props.userEntitlement === EntitlementLevel.READ_AND_WRITE_ACCESS
    );
  };

  getHasWriteAccess = (): boolean => {
    return (
      this.props.userEntitlement === EntitlementLevel.WRITE_ACCESS ||
      this.props.userEntitlement === EntitlementLevel.READ_AND_WRITE_ACCESS
    );
  };

  render = (): ReactElement => {
    return (
      <div>
        <Header
          userName={this.props.userName ?? ""}
          showAdmin={this.getHasWriteAccess()}
          showRef={this.getHasReadAccess()}
        />
        <Switch>
          <ProtectedRoute
            path={getPagePath(Pages.REFERENCE)}
            userAuthenticated={this.getHasReadAccess()}
            Comp={ApiReferenceComponent}
            defaultPage={getPagePath(Pages.LOGIN)}
          />
          <ProtectedRoute
            path={getPagePath(Pages.PROVISIONING)}
            userAuthenticated={this.getHasWriteAccess()}
            Comp={ProvisioningComponent}
            defaultPage={getPagePath(Pages.LOGIN)}
          />
          <ProtectedRoute
            path={getPagePath(Pages.ARTIFACTS)}
            userAuthenticated={this.getHasWriteAccess()}
            Comp={ArtifactComponent}
            defaultPage={getPagePath(Pages.LOGIN)}
          />
          <ProtectedRoute
            path={getPagePath(Pages.MIGRATION)}
            userAuthenticated={this.getHasWriteAccess()}
            Comp={MigrationComponent}
            defaultPage={getPagePath(Pages.LOGIN)}
          />
          <ProtectedRoute
            path={getPagePath(Pages.LOGIN)}
            Comp={loginPage}
            userAuthenticated={!this.getHasReadAccess()}
            defaultPage={getPagePath(Pages.REFERENCE)}
          />
          <Route
            exact
            path="/"
            render={() => <Redirect to={getPagePath(Pages.LOGIN)} />}
          />
          <Route path="/coqst" component={CompanyQuestionPage} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </div>
    );
  };
}

export default RouteComponent;
