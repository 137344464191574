/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import {
  ActionTypesEnum,
  RatingArtifactsActionTypes,
  RatingArtifact,
} from "./types";

export const getRatingArtifactsForCIdAction = (
  correlationId: string,
  ratingArtifacts: RatingArtifact[]
): RatingArtifactsActionTypes => {
  return {
    type: ActionTypesEnum.GET_ARTIFACTS_BY_CID,
    payload: { correlationId: correlationId, ratingArtifacts: ratingArtifacts },
  };
};
