/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { InitialStateType } from "../../app/store/rootType";
import React, { Component, ReactElement } from "react";
import { Modal, Button } from "react-bootstrap";
import { saveTenantController } from "../../app/store/provisioning/controller";
import { connect, ConnectedProps } from "react-redux";
import "./tenantList.css";

interface State {
  tenantIdText: string;
  tenantNameText: string;
  agencyIdText: number;
  errors: { [key: string]: any };
  loading: boolean;
}

interface Props {
  showModal: boolean;
  closeModal: () => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: InitialStateType) => ({});

const mapDispatchToProps = {
  saveTenantController,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type FinalProps = PropsFromRedux & Props;

class addTenantComponent extends Component<FinalProps, State> {
  constructor(props: FinalProps) {
    super(props);
    this.state = {
      tenantIdText: "",
      tenantNameText: "",
      agencyIdText: 0,
      errors: {},
      loading: false,
    };
  }

  tenantId = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      tenantIdText: e.target.value,
    });
  };

  tenantName = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      tenantNameText: e.target.value,
    });
  };

  agencyId = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      agencyIdText: parseInt(e.target.value),
    });
  };

  closeTenantModal = (): void => {
    this.setState({
      errors: {},
      tenantIdText: "",
      tenantNameText: "",
      agencyIdText: 0,
    });
    this.props.closeModal();
  };

  isValid = (): boolean => {
    const tenantErrors: { [k: string]: any } = {};
    if (this.state.tenantIdText.length < 1) {
      tenantErrors.tenantIdErr = "TenantId can't be empty!";
    }
    if (this.state.tenantNameText.length < 1) {
      tenantErrors.tenantNameErr = "Tenant Name can't be empty!";
    }
    if (this.state.agencyIdText === 0) {
      tenantErrors.agencyIdErr = "AgencyId can't be empty!";
    }
    if (
      (tenantErrors.tenantIdErr !== undefined &&
        tenantErrors.tenantIdErr !== "") ||
      (tenantErrors.tenantNameErr !== undefined &&
        tenantErrors.tenantNameErr !== "") ||
      (tenantErrors.agencyIdErr !== undefined &&
        tenantErrors.agencyIdErr !== "")
    ) {
      this.setState({ errors: tenantErrors });
      return false;
    }
    return true;
  };

  addTenant = async (): Promise<void> => {
    if (this.isValid()) {
      this.setState({
        loading: true,
      });
      await this.props.saveTenantController(
        this.state.tenantIdText,
        this.state.tenantNameText,
        this.state.agencyIdText
      );

      this.setState({
        loading: false,
      });
      this.closeTenantModal();
    }
  };

  render = (): ReactElement => {
    return (
      <Modal size="sm" show={this.props.showModal} className="mt-5">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header d-block text-center">
              <h5 className="modal-title">Add New Tenant</h5>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <div>
                  <input
                    type="text"
                    name="tenantId"
                    className="form-control"
                    placeholder="Tenant Id"
                    onChange={this.tenantId}
                  />
                </div>
                <span className="text-danger">
                  {this.state.errors.tenantIdErr}
                </span>
              </div>
              <div className="form-group">
                <div>
                  <input
                    type="text"
                    name="tenantName"
                    placeholder="Tenant Name"
                    className="form-control"
                    onChange={this.tenantName}
                  />
                </div>
                <span className="text-danger">
                  {this.state.errors.tenantNameErr}
                </span>
              </div>
              <div className="form-group">
                <div>
                  <input
                    type="number"
                    name=""
                    placeholder="Rating Agency Id"
                    className="form-control"
                    onChange={this.agencyId}
                  />
                </div>
                <span className="text-danger">
                  {this.state.errors.agencyIdErr}
                </span>
              </div>
              <div className="text-xs-center">
                <div>
                  <Button
                    variant="secondary"
                    onClick={this.closeTenantModal}
                    className="float-left"
                  >
                    Cancel
                  </Button>
                  {this.state.loading ? (
                    <Button
                      className="v-btn float-right"
                      type="button"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Saving...
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      onClick={this.addTenant}
                      className="v-btn float-right"
                    >
                      Save
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };
}

export default connector(addTenantComponent);
