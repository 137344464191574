/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { CompanyQuestionState } from "./types";

export const initialState: CompanyQuestionState = {
  companyQuestionFRD: [],
  requiredStaticQuestionFRD: [],
};
