/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { APIReturnTypes, handleError, handleResponse } from "./utils";
import { MigrationPacket } from "../../types/customTypes/ratModels";

const basePath = "api/DataDeployment";

interface RatingServicePackets {
  Packet: string;
  // TODO: add the required fields
}

export const updatePacket = async (
  migrationPackets: MigrationPacket[]
): Promise<APIReturnTypes> => {
  const ratingServicePackets: RatingServicePackets[] = [];
  if (migrationPackets != null) {
    migrationPackets.forEach((packet) => {
      const ratingPacket: RatingServicePackets = {
        Packet: JSON.stringify(packet),
      };
      ratingServicePackets.push(ratingPacket);
    });
  }

  return await fetch(basePath + "/SavePackets", {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(ratingServicePackets),
  })
    .then(handleResponse)
    .catch(handleError);
};
