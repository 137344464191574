/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import {
  ActionTypesEnum,
  ProvisioningState,
  ProvisioningActionTypes,
} from "./types";
import { initialState } from "./initialState";

export const provisioningReducer = (
  // Redux requires state to be the first parameter
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: ProvisioningActionTypes
): ProvisioningState => {
  switch (action.type) {
    case ActionTypesEnum.GET_ALL_TENANTS:
      return {
        ...state,
        allTenantInfo: action.payload,
      };
    case ActionTypesEnum.ADD_NEW_TENANT:
      return {
        ...state,
        allTenantInfo: [...state.allTenantInfo, action.payload],
      };
    default:
      return state;
  }
};
