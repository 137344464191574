/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { createBrowserHistory } from "history";
import { applyMiddleware, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./rootReducer";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { InitialStateType } from "./rootType";

export const history = createBrowserHistory();

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function configureStore(initialState: InitialStateType) {
  const store = createStore(
    persistReducer<InitialStateType>(persistConfig, createRootReducer(history)), // root reducer with router state
    // This is a bug with Redux Persist, where _persist is not optional in the state tree, but it should be
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialState as any,
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        thunk
      )
    )
  );
  const persistor = persistStore(store);

  return { store, persistor };
}
