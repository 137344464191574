/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { initialState } from "./initialState";
import { UserAuthState, UserAuthActionTypes, ActionTypesEnum } from "./types";

export function userAuthReducer(
  // Redux requires state to be the first parameter
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: UserAuthActionTypes
): UserAuthState {
  switch (action.type) {
    case ActionTypesEnum.SET_USER_NAME: {
      return {
        ...state,
        userName: action.payload,
      };
    }
    case ActionTypesEnum.SET_ENTITLEMENT_LEVEL: {
      return {
        ...state,
        userEntitlement: action.payload,
      };
    }
    default:
      return state;
  }
}
