/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import {
  RatingUnitActionTypes,
  RatingUnitState,
  ActionTypesEnum,
} from "./types";
import { initialState } from "./initialState";

const provisionedUnitCopy = (
  state: RatingUnitState
): { [tenant: string]: string[] } => {
  const newProvisionedUnits: { [tenant: string]: string[] } = {};
  Object.keys(state.provisionedUnits).forEach((tenant) => {
    newProvisionedUnits[tenant] = [...state.provisionedUnits[tenant]];
  });
  return newProvisionedUnits;
};

export const ratingUnitReducer = (
  // Redux requires state to be the first parameter
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: RatingUnitActionTypes
): RatingUnitState => {
  switch (action.type) {
    case ActionTypesEnum.SET_RATING_UNITS:
      return {
        ...state,
        ratingUnits: action.payload,
      };
    case ActionTypesEnum.SET_PROVISIONED_UNITS_FOR_TENANT: {
      const newProvisionedUnits = provisionedUnitCopy(state);
      newProvisionedUnits[action.payload.tenant] = [...action.payload.ids];
      return {
        ...state,
        provisionedUnits: newProvisionedUnits,
      };
    }

    case ActionTypesEnum.SAVE_PROVISIONED_UNITS: {
      const provisionedUnits = provisionedUnitCopy(state);
      if (provisionedUnits[action.payload.tenant] == null) {
        provisionedUnits[action.payload.tenant] = [...action.payload.ids];
      } else {
        provisionedUnits[action.payload.tenant] = [
          ...provisionedUnits[action.payload.tenant],
          ...action.payload.ids,
        ];
      }
      return {
        ...state,
        provisionedUnits: provisionedUnits,
      };
    }

    case ActionTypesEnum.REMOVE_PROVISIONED_UNITS: {
      const provisionedUnits = provisionedUnitCopy(state);
      if (provisionedUnits[action.payload.tenant] == null) {
        return state;
      }
      const newProvisionedIds = provisionedUnits[action.payload.tenant].filter(
        (id) => !action.payload.ids.includes(id)
      );
      provisionedUnits[action.payload.tenant] = newProvisionedIds;
      return {
        ...state,
        provisionedUnits: provisionedUnits,
      };
    }

    default:
      return state;
  }
};
