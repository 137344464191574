/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { Component, ReactElement } from "react";
import { InitialStateType } from "../../app/store/rootType";
import { connect, ConnectedProps } from "react-redux";
import { getArtifactsController } from "../../app/store/artifact/controller";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import "./artifact.css";
import "../companyQuestions/companyQuestion.css";
import { getAllTenantInfoController } from "../../app/store/provisioning/controller";
// import { TenantInfo } from "../../app/store/provisioning/types";
import Collapsible from "../../components/collapsible/collapsible";
import * as artifactsApi from "../../app/api/artifact";
import { RatingArtifact } from "../../app/store/artifact/types";
import SelectSearch, {
  fuzzySearch,
  SelectSearchOption,
} from "react-select-search";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: InitialStateType) => ({
  allTenantInfo: state.provisioning.allTenantInfo,
  ratingArtifacts: state.artifacts.ratingArtifacts,
});

const mapDispatchToProps = {
  getAllTenantInfoController,
  getArtifactsController,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface State {
  selectedTenant: string;
  correlationId: string;
  loading: boolean;
  errorMsg: string;
  artifacts: RatingArtifact[];
  tenants: SelectSearchOption[];
}

type Props = PropsFromRedux;

let inputCorrelationId = "";
class ArtifactViewerComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedTenant: "",
      correlationId: "",
      loading: false,
      errorMsg: "",
      artifacts: [],
      tenants: [],
    };
  }

  correlationText = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      correlationId: e.target.value,
    });
  };

  componentDidMount = async (): Promise<void> => {
    if (
      this.props.allTenantInfo === undefined ||
      this.props.allTenantInfo.length === 0
    ) {
      await this.loadAllTenantInfo();
    }
    this.fillTenants();
  };

  loadAllTenantInfo = async (): Promise<void> => {
    await this.props.getAllTenantInfoController();
  };

  loadArtifacts = async (): Promise<void> => {
    if (this.state.correlationId?.length < 1) {
      this.setState({ errorMsg: "Please enter a valid correlationId." });
      return;
    }
    this.setState({ errorMsg: "" });
    const arfts = this.props.ratingArtifacts[this.state.correlationId];
    inputCorrelationId = this.state.correlationId;
    this.setState({
      loading: true,
    });
    if (arfts === undefined || arfts?.length === 0) {
      await this.props.getArtifactsController(
        this.state.selectedTenant,
        this.state.correlationId
      );
    }
    this.setState({
      artifacts: this.props.ratingArtifacts[this.state.correlationId],
    });
    this.setState({
      loading: false,
    });
  };

  downloadArtifacts = async (): Promise<void> => {
    await artifactsApi.downloadArtifacts(
      this.state.selectedTenant,
      this.state.correlationId
    );
  };

  fillTenants = (): void => {
    const tntList: SelectSearchOption[] = [];
    const verTenant = { name: "VERTAFORE", value: "VERTAFORE" };
    tntList.push(verTenant);
    this.props.allTenantInfo.forEach((tenant) => {
      tntList.push({
        name:
          tenant.tenantName == null || tenant.tenantName === undefined
            ? ""
            : tenant.tenantName,
        value:
          tenant.tenantId == null || tenant.tenantId === undefined
            ? ""
            : tenant.tenantId,
      });
    });
    this.setState({ tenants: tntList, selectedTenant: "VERTAFORE" });
  };

  handleChange = (...args: any[]): void => {
    this.setState({
      selectedTenant: args[0],
    });
  };

  render = (): ReactElement => {
    return (
      <Container fluid>
        <Row className="mt-2">
          <Col>
            <label className="select-label text-orng float-left">
              TenantId:{" "}
            </label>
            <div className="float-left">
              <SelectSearch
                options={this.state.tenants}
                search
                filterOptions={fuzzySearch}
                placeholder="Search tenants"
                onChange={this.handleChange}
                value={this.state.selectedTenant}
                emptyMessage="Not found"
              />
            </div>
            <label className="select-label ml-2">CorrelationId: </label>
            <input
              type="text"
              name="correlationId"
              placeholder="CorrelationId"
              value={this.state.correlationId}
              className="input-sm corrIdTxt"
              onChange={this.correlationText}
            />
            <Button className="btn-sm v-btn ml-2" onClick={this.loadArtifacts}>
              Get Artifacts{" "}
              <i className="fa fa-arrow-circle-right" aria-hidden="true"></i>
            </Button>
          </Col>
          <Col className="ml-2">
            {this.state.artifacts?.length !== 0 && (
              <Button
                className="btn-sm v-btn float-right"
                onClick={this.downloadArtifacts}
              >
                <i className="fa fa-download" aria-hidden="true"></i> Download
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <span className="text-danger">
            {this.state.errorMsg !== "" ? <div>{this.state.errorMsg}</div> : ""}
          </span>
        </Row>
        <Row>
          <Col>To be Done!</Col>
          {this.state.loading ? (
            <Col className="tenantloader">
              {""}
              <h1>Retreiving artifacts. This may take a minute.</h1>
              <Spinner animation="border" />
            </Col>
          ) : this.state.artifacts?.length !== 0 ? (
            <Col>
              <Row>
                <Col>TenantId</Col>
                <Col> : {this.state.selectedTenant}</Col>
              </Row>
              <Row>
                <Col>CorrelationId</Col>
                <Col> : {inputCorrelationId}</Col>
              </Row>
              <Row>
                <Col>Transaction Date</Col>
                <Col> : TBD</Col>
              </Row>

              <div>
                {this.state.artifacts?.map((artifact, index) => (
                  <Collapsible
                    key={index}
                    title={artifact.artifactType ?? "NULL"}
                    collapseType="inner"
                  >
                    <div>{artifact.payload}</div>
                  </Collapsible>
                ))}
              </div>
            </Col>
          ) : (
            <Col>Please enter a valid correlation to view the artifacts.</Col>
          )}
        </Row>
      </Container>
    );
  };
}

export default connector(ArtifactViewerComponent);
