/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import * as artifactsApi from "../../api/artifact";
import { isTypeOf } from "../../../util/typeGuards";
import { RatingArtifactsActionTypes, RatingArtifact } from "./types";
import { getRatingArtifactsForCIdAction } from "./actions";
import { Dispatch } from "redux";

export const getArtifactsController = (
  tenantId: string,
  correlationId: string
) => {
  return async (
    dispatch: Dispatch<RatingArtifactsActionTypes>
  ): Promise<void> => {
    return await artifactsApi
      .getRatingArtifactsByCorrelationId(tenantId, correlationId)
      .then((artifacts) => {
        if (
          !Array.isArray(artifacts) ||
          !isTypeOf<RatingArtifact>(artifacts[0], "payload")
        ) {
          // throw new Error("Error communicating with API");
        }
        dispatch(
          getRatingArtifactsForCIdAction(
            correlationId,
            artifacts as RatingArtifact[]
          )
        );
      })
      .catch((error) => {
        throw error;
      });
  };
};
