/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */

// import TenantListComponent from "../../components/provisioning/tenantList";
import React, { Component, ReactElement } from "react";
import { Container, Row } from "react-bootstrap";

// import { RatingArtifactV1 } from "../../app/store/artifact/types";
import ArtifactViewerComponent from "../../components/artifact/artifactViewer";
// import ArtifactDetailsComponent from "../../components/artifact/ArtifactDetails";
// import { RatingArtifactV1 } from "../../app/store/artifact/types";
// import ArtifactViewerComponent from "../../components/artifact/artifactViewer";
interface State {
  tenantId: string;
  correlationId: string;
}

class ArtifactComponent extends Component<unknown, State> {
  render = (): ReactElement => {
    return (
      <Container fluid className="pl-4">
        <Row>
          <h3>Artifacts</h3>
        </Row>
        {/* {this.state.correlationId !== undefined ? (
                <ArtifactDetailsComponent
                  correlationId={this.state.correlationId}
                  selectedTenant={this.state.tenantId}
                />
              ) : (
                ""
              )} */}
        <Row>
          <ArtifactViewerComponent />
        </Row>
      </Container>
    );
  };
}

export default ArtifactComponent;
