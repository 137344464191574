/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import * as flatRatingDataAPI from "../../api/flatRatingData";
// import { Dispatch } from "react";
import { isTypeOf } from "../../../util/typeGuards";
import { CompanyQuestionActionTypes } from "./types";
import {
  addCompanyQuestion,
  addCompanyQuestionArray,
  addRequiredStaticQuestionArray,
} from "./actions";
import { Dispatch } from "redux";
import { FlatRatingDataV1 } from "../../../types/customTypes/ratModels";

export const addCompanyQuestionsByRatingDataController = (
  ratingDataId: string
) => {
  return async (
    dispatch: Dispatch<CompanyQuestionActionTypes>
  ): Promise<void> => {
    return await flatRatingDataAPI
      .getFlatRatingDataByRatingDataId(ratingDataId)
      .then((flatRatingData) => {
        if (
          !isTypeOf<FlatRatingDataV1>(flatRatingData, [
            "apiPropertyId",
            "apiTypeId",
          ])
        ) {
          throw new Error("Error communicating with API");
        }
        dispatch(addCompanyQuestion(flatRatingData));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const addCompanyQuestionsByRatingUnitController = (
  ratingUnitId: number
) => {
  return async (
    dispatch: Dispatch<CompanyQuestionActionTypes>
  ): Promise<void> => {
    return await flatRatingDataAPI
      .getFlatRatingDataByRatingUnitId(ratingUnitId, false)
      .then((flatRatingDataArr) => {
        if (
          !Array.isArray(flatRatingDataArr) ||
          (flatRatingDataArr.length > 0 &&
            !isTypeOf<FlatRatingDataV1>(flatRatingDataArr[0], [
              "apiPropertyId",
              "apiTypeId",
            ]))
        ) {
          throw new Error("Error communicating with API");
        }
        dispatch(
          addCompanyQuestionArray(flatRatingDataArr as FlatRatingDataV1[])
        );
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const addRequiredStaticQuestionsByRatingUnitController = (
  ratingUnitId: number
) => {
  return async (
    dispatch: Dispatch<CompanyQuestionActionTypes>
  ): Promise<void> => {
    return await flatRatingDataAPI
      .getFlatRatingDataRequiredStaticQuestions(ratingUnitId)
      .then((flatRatingDataArr) => {
        if (
          !Array.isArray(flatRatingDataArr) ||
          (flatRatingDataArr.length > 0 &&
            !isTypeOf<FlatRatingDataV1>(flatRatingDataArr[0], [
              "apiPropertyId",
              "apiTypeId",
            ]))
        ) {
          throw new Error("Error communicating with API");
        }
        dispatch(
          addRequiredStaticQuestionArray(
            flatRatingDataArr as FlatRatingDataV1[]
          )
        );
      })
      .catch((error) => {
        throw error;
      });
  };
};
