/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { ActionTypesEnum, RatingUnitActionTypes } from "./types";
import { RatingUnit } from "../../../types/customTypes/ratModels";

export const setRatingUnits = (
  ratingUnits: RatingUnit[]
): RatingUnitActionTypes => {
  return {
    type: ActionTypesEnum.SET_RATING_UNITS,
    payload: ratingUnits,
  };
};

export const setProvisionedUnitsForTenantAction = (
  tenant: string,
  ids: string[]
): RatingUnitActionTypes => {
  return {
    type: ActionTypesEnum.SET_PROVISIONED_UNITS_FOR_TENANT,
    payload: { tenant: tenant, ids: ids },
  };
};

export const saveProvisionedUnitsAction = (
  tenant: string,
  ids: string[]
): RatingUnitActionTypes => {
  return {
    type: ActionTypesEnum.SAVE_PROVISIONED_UNITS,
    payload: { tenant: tenant, ids: ids },
  };
};

export const removeProvisionedUnitsAction = (
  tenant: string,
  ids: string[]
): RatingUnitActionTypes => {
  return {
    type: ActionTypesEnum.REMOVE_PROVISIONED_UNITS,
    payload: { tenant: tenant, ids: ids },
  };
};
