/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import {
  RatingDataSampleActionTypes,
  RatingDataSampleState,
  ActionTypesEnum,
} from "./types";
import { initialState } from "./initialState";

export function ratingDataSampleReducer(
  // Redux requires state to be the first parameter
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: RatingDataSampleActionTypes
): RatingDataSampleState {
  switch (action.type) {
    case ActionTypesEnum.RATING_DATA_SAMPLE:
      return {
        ...state,
        ratingDataSamples: action.payload,
      };
    default:
      return state;
  }
}
