/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import {
  StaticQuestionActionTypes,
  StaticQuestionsState,
  ActionTypesEnum,
} from "./types";
import { initialState } from "./initialState";

const staticQuestionStateLinkCopy = (
  storeState: StaticQuestionsState
): { [state: string]: string[] } => {
  const newStaticQuestionStateLink: { [state: string]: string[] } = {};
  Object.keys(storeState.staticQuestionStateLinkIds).forEach((state) => {
    newStaticQuestionStateLink[state] = [
      ...storeState.staticQuestionStateLinkIds[state],
    ];
  });
  return newStaticQuestionStateLink;
};

export function staticQuestionReducer(
  // Redux requires state to be the first parameter
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: StaticQuestionActionTypes
): StaticQuestionsState {
  switch (action.type) {
    case ActionTypesEnum.SET_STATIC_QUESTIONS:
      return {
        ...state,
        staticQuestionFRD: action.payload,
      };

    case ActionTypesEnum.SET_STATIC_QUESTION_STATE_LINK_IDS: {
      const staticQuestionStateLinkIds = staticQuestionStateLinkCopy(state);
      staticQuestionStateLinkIds[action.payload.state] =
        action.payload.staticQuestionStateLinkIds;
      return {
        ...state,
        staticQuestionStateLinkIds: staticQuestionStateLinkIds,
      };
    }
    case ActionTypesEnum.SET_JSON_HIERARCHY:
      return {
        ...state,
        jsonHierarchyModel: action.payload,
      };

    default:
      return state;
  }
}
