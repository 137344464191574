/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { connect, ConnectedProps } from "react-redux";
import { InitialStateType } from "../app/store/rootType";
import RouteComponent from "./RouteComponent";
import { logoutController } from "../app/store/userAuth/controller";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: InitialStateType) => ({
  userEntitlement: state.userAuth.userEntitlement,
  userName: state.userAuth.userName,
});

const mapDispatchToProps = {
  logoutController,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RouteComponent);
