/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import classNames from "classnames";
import React, { Component, ReactElement } from "react";
import "./collapsible.css";

interface CollapsibleProps {
  title: string;
  collapseType?: string;
}

export default class Collapsible extends Component<CollapsibleProps> {
  toggleCollapsible = (e: React.MouseEvent<HTMLElement>): void => {
    const collapsible = e.currentTarget;
    const content = collapsible.nextElementSibling;
    const icon = collapsible.firstElementChild;

    if (this.props.collapseType === "uppermiddle") {
      const moddlelist = document.getElementsByClassName(
        "uppermiddleCollapseContent"
      );
      const upIcons = document.querySelectorAll(
        ".uppermiddleCollapse .fa-chevron-circle-up"
      );
      upIcons.forEach(function (icn) {
        icn.className = "fas fa-chevron-circle-down";
      });

      for (let i = 0; i < moddlelist.length; i++) {
        if (moddlelist[i] !== content) {
          moddlelist[i].setAttribute("style", "display: none");
        }
      }
    }

    if (this.props.collapseType === "middle") {
      const moddlelist = document.getElementsByClassName(
        "middleCollapseContent"
      );
      const middleUpIcons = document.querySelectorAll(
        ".middleCollapse .fa-chevron-circle-up"
      );
      middleUpIcons.forEach(function (icn) {
        icn.className = "fas fa-chevron-circle-down";
      });
      for (let i = 0; i < moddlelist.length; i++) {
        if (moddlelist[i] !== content) {
          moddlelist[i].setAttribute("style", "display: none");
        }
      }
    }
    if (this.props.collapseType === "outer") {
      const list = document.getElementsByClassName("outerCollapseContent");

      const outerIcons = document.querySelectorAll(
        ".outerCollapse .fa-chevron-circle-up"
      );
      outerIcons.forEach(function (icn) {
        icn.className = "fas fa-chevron-circle-down";
      });

      for (let i = 0; i < list.length; i++) {
        if (list[i] !== content) {
          list[i].setAttribute("style", "display: none");
        }
      }
    }
    if (this.props.collapseType === "inner") {
      const innerIcons = document.querySelectorAll(
        ".innerCollapse .fa-chevron-circle-up"
      );
      innerIcons.forEach(function (icn) {
        icn.className = "fas fa-chevron-circle-down";
      });
      const listinner = document.getElementsByClassName("innerCollapseContent");
      for (let i = 0; i < listinner.length; i++) {
        if (listinner[i] !== content) {
          listinner[i].setAttribute("style", "display: none");
        }
      }
    }
    collapsible?.classList.toggle("collapsible-active");
    if (content !== null) {
      if (content.getAttribute("style")?.includes("display: block") ?? false) {
        content.setAttribute("style", "display: none");
        if (icon !== null) {
          icon.className = "fas fa-chevron-circle-down";
        }
      } else {
        content.setAttribute("style", "display: block");
        if (icon !== null) {
          icon.className = "fas fa-chevron-circle-up";
        }
      }
    }
  };

  render = (): ReactElement => {
    const collapseType = classNames({
      outerCollapse: this.props.collapseType === "outer",
      innerCollapse: this.props.collapseType === "inner",
      middleCollapse: this.props.collapseType === "middle",
      uppermiddleCollapse: this.props.collapseType === "uppermiddle",
    });

    const collapseClasses = classNames(collapseType, {
      "collapsible-container": true,
    });
    const collapseContentClasses = classNames(collapseType, {
      "collapsible-content": true,
    });

    const buttonClass = classNames(collapseType + "-button", {
      collapsible: true,
    });

    return (
      <div className={collapseClasses}>
        <button
          type="button"
          id="collapsible"
          className={buttonClass}
          onClick={this.toggleCollapsible}
        >
          {this.props.title}
          <i
            id="button-icon"
            className="fas fa-chevron-circle-down align-middle"
          ></i>
        </button>
        <div id="collapsible-content" className={collapseContentClasses}>
          {this.props.children}
        </div>
      </div>
    );
  };
}
