/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import { staticQuestionReducer } from "./staticQuestions/reducers";
import { userAuthReducer } from "./userAuth/reducer";
import { companyQuestionReducer } from "./companyQuestions/reducer";
import { ratingUnitReducer } from "./ratingUnits/reducers";
import { provisioningReducer } from "./provisioning/reducer";
import { ratingDataSampleReducer } from "./ratingSample/reducers";
import { ratingArtifactReducer } from "./artifact/reducer";
import { ratingDataPacketReducer } from "./ratingDataPacket/reducers";

import {
  InitialStateType,
  RootActionTypes,
  RootActionTypesEnum,
} from "./rootType";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const createRootReducer = (history: History) => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    staticQuestions: staticQuestionReducer,
    userAuth: userAuthReducer,
    companyQuestions: companyQuestionReducer,
    ratingUnits: ratingUnitReducer,
    provisioning: provisioningReducer,
    ratingDataSamples: ratingDataSampleReducer,
    artifacts: ratingArtifactReducer,
    ratingDataPackets: ratingDataPacketReducer,
  });

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const rootReducer = (
    state: InitialStateType | undefined,
    action: RootActionTypes
  ) => {
    if (action.type === RootActionTypesEnum.RESET) {
      state = undefined;
    }
    return appReducer(state, action);
  };
  return rootReducer;
};
export default createRootReducer;

export type RootState = ReturnType<typeof createRootReducer>;
