/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { ActionTypesEnum, ProvisioningActionTypes, TenantInfo } from "./types";

export const getAllTenantInfo = (
  allTenantInfo: TenantInfo[]
): ProvisioningActionTypes => {
  return {
    type: ActionTypesEnum.GET_ALL_TENANTS,
    payload: allTenantInfo,
  };
};

export const saveTenantInfo = (
  tenantInfo: TenantInfo
): ProvisioningActionTypes => {
  return {
    type: ActionTypesEnum.ADD_NEW_TENANT,
    payload: tenantInfo,
  };
};
