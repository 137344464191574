/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { EntitlementLevel } from "../../../types/enums/entitlementLevel";
import { UserAuthState } from "./types";

export const initialState: UserAuthState = {
  userName: undefined,
  userEntitlement: EntitlementLevel.NO_ACCESS,
};
