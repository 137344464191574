/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { handleResponse, handleError, APIReturnTypes } from "./utils";
import { LoginRequest } from "../../types/customTypes/ratModels";

const baseUrl = "api/Auth/";

export const signIn = async (
  loginRequest: LoginRequest
): Promise<APIReturnTypes> => {
  return await fetch(baseUrl + "SignIn", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(loginRequest),
  })
    .then(handleResponse)
    .catch(handleError);
};

export const signOut = async (): Promise<APIReturnTypes> => {
  return await fetch(baseUrl + "SignOut", {
    method: "POST",
  })
    .then(handleResponse)
    .catch(handleError);
};

export const getAccessLevel = async (): Promise<APIReturnTypes> => {
  return await fetch(baseUrl + "GetAccessLevel")
    .then(async (response) => await handleResponse(response, false))
    .catch(handleError);
};
