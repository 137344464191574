/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { RootActionTypesEnum, RootActionTypes } from "./rootType";

export function reset(): RootActionTypes {
  return {
    type: RootActionTypesEnum.RESET,
  };
}
