/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
export enum EntitlementLevel {
  NO_ACCESS = "NO_ACCESS",
  WRITE_ACCESS = "WRITE_ACCESS",
  READ_ACCESS = "READ_ACCESS",
  READ_AND_WRITE_ACCESS = "READ_AND_WRITE_ACCESS",
}
