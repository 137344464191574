/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
// TypeScript has some type-checking using the instanceof operator, however that only works on classes. If we need to check a type that isn't an instance of a class, we can define a type-guard
// The return type of the function tells the TS compiler that if the function returns true, we know that variable is a certain type

export const isTypeOf = <T>(
  varToCheck: unknown,
  propertyToCheckFor: keyof T | Array<keyof T>
): varToCheck is T => {
  if (varToCheck === undefined) {
    return false;
  }
  if (Array.isArray(propertyToCheckFor)) {
    for (const property of propertyToCheckFor) {
      if ((varToCheck as T)[property] === undefined) {
        return false;
      }
    }
    return true;
  } else {
    return (varToCheck as T)[propertyToCheckFor] !== undefined;
  }
};

export const notEmpty = <T>(value: T | null | undefined): value is T => {
  return value != null;
};
