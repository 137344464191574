/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { InitialStateType } from "../../app/store/rootType";
import React, { ChangeEvent, Component, ReactElement } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import {
  signInController,
  getEntitlementLevelController,
  logoutController,
} from "../../app/store/userAuth/controller";
import { getPagePath } from "../../routes/RouteComponent";
import { Pages } from "../../types/enums/pages";
import "./login.css";

const mapDispatchToProps = {
  getTokenController: signInController,
  getEntitlementLevelController,
  logoutController,
  signInController,
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: InitialStateType) => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & RouteComponentProps;

interface State {
  userName: string;
  password: string;
  showInvalidLogin: boolean;
}

class LoginComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      showInvalidLogin: false,
    };
  }

  componentDidMount = (): void => {};

  handleChange = (element: ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      ...this.state,
      [element.target.name]: element.target.value,
    });
  };

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    await this.login();
  };

  login = async (): Promise<void> => {
    await this.props
      .signInController(this.state.userName, this.state.password)
      .then(async () => {
        await this.props.getEntitlementLevelController().then(() => {
          // Redirect. There might be a better way to do this, but this is the only way I could find that works
          // https://stackoverflow.com/a/56271037
          this.props.history.push(getPagePath(Pages.REFERENCE));
        });
      })
      .catch(async () => {
        await this.props.logoutController();
        this.setState({ ...this.state, showInvalidLogin: true });
      });
  };

  render = (): ReactElement => {
    return (
      <div className="login-bg min-vh-100">
        {this.state.showInvalidLogin ? (
          <Alert
            className="login-error text-center"
            onClose={() =>
              this.setState({ ...this.state, showInvalidLogin: false })
            }
            dismissible
          >
            <Alert.Heading>
              Error logging in. Check that your username and password are
              correct.
            </Alert.Heading>
          </Alert>
        ) : (
          <div />
        )}
        <div className="mx-auto text-center">
          <div className="pt-5">
            <img
              src="https://www.vertafore.com/themes/custom/themekit/logo.svg"
              alt="logo"
              width="150"
              height="75"
            />
          </div>
          <h3 className="mt-2">Rating API Utility Login</h3>
        </div>
        <div className="login-box mt-3">
          <Form className="w-100 p-3" onSubmit={this.handleSubmit}>
            <Form.Group controlId="formEmail">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username"
                name="userName"
                onChange={this.handleChange}
              />
              {/* <Form.Text className="text-muted">
                Your username for accessing the Titan API
              </Form.Text> */}
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                name="password"
                onChange={this.handleChange}
              />
            </Form.Group>
            <div className="col-md-12 text-center">
              <Button variant="primary" className="orange-btn" type="submit">
                Login
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  };
}

export default withRouter(connector(LoginComponent));
