/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { Component, ReactElement } from "react";
import { InitialStateType } from "../../app/store/rootType";
import { connect, ConnectedProps } from "react-redux";
import { getAllTenantInfoController } from "../../app/store/provisioning/controller";
import { TenantInfo } from "../../app/store/provisioning/types";
import ReactPaginate from "react-paginate";
import { Button, Col, Container, Row, Spinner, Table } from "react-bootstrap";
import "./tenantList.css";
import "../companyQuestions/companyQuestion.css";
import AddTenantComponent from "./addTenantModal";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: InitialStateType) => ({
  allTenantInfo: state.provisioning.allTenantInfo,
});

const mapDispatchToProps = {
  getAllTenantInfoController,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ProvisioningProps {
  selectedTenant: TenantInfo | undefined;
  tenantClick: ((tenant: TenantInfo) => void) | undefined;
}

type Props = PropsFromRedux & ProvisioningProps;

interface State {
  selectedTenant: TenantInfo | undefined;
  filterText: string;
  loading: boolean;
  currentPage: number;
  pageSize: number;
  numPages: number;
  pagedTenants: TenantInfo[];
  showTenantModal: boolean;
}

class tenantListComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filterText: "",
      selectedTenant: undefined,
      loading: false,
      currentPage: 1,
      pageSize: 20,
      numPages: 1,
      pagedTenants: [],
      showTenantModal: false,
    };
  }

  handlePageSelect = (selectedPage: { selected: number }): void => {
    this.setState({
      currentPage: selectedPage.selected + 1,
    });
  };

  componentDidMount = async (): Promise<void> => {
    if (
      this.props.allTenantInfo === undefined ||
      this.props.allTenantInfo.length === 0
    ) {
      await this.loadAllTenantInfo();
    }

    this.setState(
      {
        selectedTenant:
          this.props.allTenantInfo.length > 0
            ? this.props.allTenantInfo[0]
            : undefined,
      },
      () => {
        if (
          this.props.tenantClick !== undefined &&
          this.state.selectedTenant !== undefined
        ) {
          this.props.tenantClick(this.state.selectedTenant);
        }
      }
    );

    this.getNumPages();
  };

  componentDidUpdate = (): void => {
    this.getNumPages();
  };

  getNumPages = (): void => {
    const numPages = Math.ceil(
      this.getFilteredTenants().length / this.state.pageSize
    );

    if (this.state.numPages !== numPages) {
      this.setState({
        numPages: numPages,
      });
    }
  };

  getPagedTenants = (): TenantInfo[] => {
    let pagedCompanies: TenantInfo[];
    this.getNumPages();
    if (this.getFilteredTenants().length > 1) {
      pagedCompanies = this.getFilteredTenants()?.sort((tenant1, tenant2) => {
        if (tenant1.tenantName == null && tenant2.tenantName == null) return 0;
        else if (tenant1.tenantName == null) return -1;
        else if (tenant2.tenantName == null) return 1;
        else if (tenant1.tenantName < tenant2.tenantName) return -1;
        else if (tenant1.tenantName > tenant2.tenantName) return 1;
        return 0;
      });
      const spliceStart: number =
        (this.state.currentPage - 1) * this.state.pageSize;
      const spliceEnd: number =
        this.state.currentPage !== this.state.numPages
          ? spliceStart + this.state.pageSize
          : pagedCompanies.length;
      return pagedCompanies.slice(spliceStart, spliceEnd);
    } else {
      pagedCompanies = this.getFilteredTenants();
      return pagedCompanies;
    }
  };

  loadAllTenantInfo = async (): Promise<void> => {
    this.setState({
      loading: true,
    });
    await this.props.getAllTenantInfoController();

    this.setState({
      loading: false,
    });
  };

  filter = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      filterText: e.target.value,
    });
  };

  getFilteredTenants = (): TenantInfo[] => {
    if (this.state.filterText === "") {
      return this.props.allTenantInfo;
    }
    const result: TenantInfo[] = this.props.allTenantInfo.filter((tnt) => {
      return (
        this.stringContains(tnt.tenantId, this.state.filterText) ||
        this.stringContains(tnt.tenantName, this.state.filterText)
      );
    });
    return result;
  };

  stringContains = (text: string | undefined, filterText: string): boolean => {
    if (text !== undefined) {
      return text.toLowerCase().includes(filterText.toLowerCase());
    }
    return false;
  };

  showTenantModal = (): void => {
    this.setState({
      showTenantModal: true,
    });
  };

  hideTenantModal = (): void => {
    this.setState({
      showTenantModal: false,
    });
  };

  render = (): ReactElement => {
    const pagedTenants = this.getPagedTenants();
    const tenantClickFun = this.props.tenantClick;
    return (
      <div>
        <Container fluid className="pt-2">
          <h5>Tenant List</h5>
          <Row className="mt-2">
            <Col className="form-group has-search">
              <span className="fa fa-search form-control-search"></span>
              <input
                type="text"
                name="search"
                placeholder="Filter"
                onChange={this.filter}
                className="form-control"
              />
            </Col>
            <Col>
              <Button onClick={this.showTenantModal} className="btn-sm v-btn">
                <i className="fa fa-plus"></i> Add New Tenant
              </Button>
            </Col>
          </Row>
          {this.state.loading ? (
            <div className="tenantloader">
              {" "}
              <Spinner animation="border" />
            </div>
          ) : pagedTenants.length === 0 ? (
            <p>No tenant available</p>
          ) : (
            <Table className="company-view-table" striped>
              <thead>
                <tr>
                  <th>Tenant Id</th>
                  <th>Tenant Name</th>
                  <th>Agency Id</th>
                </tr>
              </thead>
              <tbody>
                {pagedTenants.map((tenant, index) => {
                  return (
                    <tr
                      key={tenant.tenantId}
                      onClick={
                        tenantClickFun != null
                          ? () => tenantClickFun(tenant)
                          : (undefined as any)
                      }
                      className={
                        this.props.selectedTenant !== undefined &&
                        tenant.tenantId === this.props.selectedTenant.tenantId
                          ? "active"
                          : ""
                      }
                    >
                      <td>{tenant.tenantId}</td>
                      <td>{tenant.tenantName}</td>
                      <td>{tenant.ratingAgencyId}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
          {this.state.loading
            ? ""
            : this.state.numPages > 1 && (
                <ReactPaginate
                  previousLabel={"← Previous"}
                  nextLabel={"Next →"}
                  pageCount={this.state.numPages}
                  onPageChange={this.handlePageSelect}
                  previousLinkClassName={"page-item page-link text-orng"}
                  nextLinkClassName={"page-item page-link text-orng"}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link text-orng"}
                  disabledClassName={"disabled"}
                  activeClassName={"active"}
                  activeLinkClassName={"active-page"}
                  pageRangeDisplayed={4}
                  marginPagesDisplayed={1}
                />
              )}
        </Container>
        <AddTenantComponent
          showModal={this.state.showTenantModal}
          closeModal={this.hideTenantModal}
        />
      </div>
    );
  };
}

export default connector(tenantListComponent);
