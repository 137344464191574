/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import * as provisioningApi from "../../api/provisioning";
import { isTypeOf } from "../../../util/typeGuards";
import { ProvisioningActionTypes, TenantInfo } from "./types";
import { getAllTenantInfo, saveTenantInfo } from "./actions";
import { Dispatch } from "redux";

export const getAllTenantInfoController = () => {
  return async (dispatch: Dispatch<ProvisioningActionTypes>): Promise<void> => {
    return await provisioningApi
      .getAllTenantInfo()
      .then((allTenantInfo) => {
        if (
          !Array.isArray(allTenantInfo) ||
          !isTypeOf<TenantInfo>(allTenantInfo[0], "tenantId")
        ) {
          throw new Error("Error communicating with API");
        }
        dispatch(getAllTenantInfo(allTenantInfo as TenantInfo[]));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const saveTenantController = (
  tenantId: string,
  tenantName: string,
  ratingAgencyId: number
) => {
  return async (dispatch: Dispatch<ProvisioningActionTypes>): Promise<void> => {
    return await provisioningApi
      .saveTenant(tenantId, tenantName, ratingAgencyId)
      .then((tenantInfo) => {
        if (!isTypeOf<TenantInfo>(tenantInfo, "tenantId")) {
          throw new Error("Error communicating with API");
        }
        dispatch(saveTenantInfo(tenantInfo));
      })
      .catch((error) => {
        throw error;
      });
  };
};
