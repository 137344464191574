/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { ComponentClass, ReactElement, Component } from "react";
import { Redirect, Route } from "react-router";

interface ProtectedRouteProps {
  Comp: ComponentClass;
  userAuthenticated: boolean;
  defaultPage: string;
  // This is to allow the spread operator for props
  [x: string]: unknown;
}

class ProtectedRoute extends Component<ProtectedRouteProps> {
  render(): ReactElement {
    const { Comp, userAuthenticated, defaultPage, ...props } = this.props;

    return (
      <Route
        {...props}
        render={(props) =>
          userAuthenticated ? (
            <Comp {...props} />
          ) : (
            <Redirect to={defaultPage} />
          )
        }
      />
    );
  }
}

export default ProtectedRoute;
