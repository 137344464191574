/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { ActionTypesEnum, UserAuthActionTypes } from "./types";
import { EntitlementLevel } from "../../../types/enums/entitlementLevel";

export function setUserName(userName: string | undefined): UserAuthActionTypes {
  return {
    type: ActionTypesEnum.SET_USER_NAME,
    payload: userName,
  };
}

export function setEntitlementLevel(
  entitlementLevel: EntitlementLevel
): UserAuthActionTypes {
  return {
    type: ActionTypesEnum.SET_ENTITLEMENT_LEVEL,
    payload: entitlementLevel,
  };
}
