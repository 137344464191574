/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { AnyAction } from "@reduxjs/toolkit";
import { RouterState } from "connected-react-router";
import { ThunkAction } from "redux-thunk";
import { CompanyQuestionState } from "./companyQuestions/types";
import { RatingUnitState } from "./ratingUnits/types";
import { RootState } from "./rootReducer";
import { StaticQuestionsState } from "./staticQuestions/types";
import { UserAuthState } from "./userAuth/types";
import { ProvisioningState } from "./provisioning/types";
import { RatingDataSampleState } from "./ratingSample/types";
import { RatingArtifactState } from "./artifact/types";
import { RatingDataPacketState } from "./ratingDataPacket/types";

export enum RootActionTypesEnum {
  RESET = "RESET",
}

export interface InitialStateType {
  router: RouterState;
  userAuth: UserAuthState;
  staticQuestions: StaticQuestionsState;
  companyQuestions: CompanyQuestionState;
  ratingUnits: RatingUnitState;
  provisioning: ProvisioningState;
  ratingDataSamples: RatingDataSampleState;
  artifacts: RatingArtifactState;
  ratingDataPackets: RatingDataPacketState;
}

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;

interface ResetAction {
  type: RootActionTypesEnum.RESET;
}

export type RootActionTypes = ResetAction;
