/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { Component, ReactElement } from "react";
import CompanyView from "./companyView";
import { InitialStateType } from "../../app/store/rootType";
import Spinner from "react-bootstrap/Spinner";
import { connect, ConnectedProps } from "react-redux";
import { getRatingUnitsController } from "../../app/store/ratingUnits/controller";
import { Container, Col, Row } from "react-bootstrap";
import RatingUnitDetailsComponent from "./ratingUnitDetails";
import RatingUnitRatingDataComponent from "../ratingUnitRatingData/ratingUnitRatingData";
import Collapsible from "../collapsible/collapsible";
import RequiredStaticQuestionsComponent from "../ratingUnitRatingData/requiredStaticQuestions";

import { RatingUnit } from "../../types/customTypes/ratModels";

interface IState {
  selectedCompany: RatingUnit | null;
  filterText: string;
  loading: boolean;
  rusToShow: RatingUnit[];
}

interface RatingUnitProps {
  state: string;
  lineOfBusiness: string;
}

function sortRatingUnits(unit1: RatingUnit, unit2: RatingUnit): number {
  if (unit1.name == null && unit2.name == null) return 0;
  else if (unit1.name == null) return -1;
  else if (unit2.name == null) return 1;
  else if (unit1.name < unit2.name) return -1;
  else if (unit1.name > unit2.name) return 1;
  return 0;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: InitialStateType) => ({
  staticQuestionFRD: state.staticQuestions.staticQuestionFRD,
  ratingUnits: state.ratingUnits.ratingUnits.sort(sortRatingUnits),
});

const mapDispatchToProps = {
  getRatingUnitsController,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & RatingUnitProps;

class CompanyQuestionPage extends Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedCompany: null,
      filterText: "",
      loading: false,
      rusToShow: [],
    };
  }

  componentDidMount = async (): Promise<void> => {
    if (this.props.ratingUnits.length <= 0) {
      await this.loadCompanies();
    }
    this.setRUsToShow();
  };

  componentDidUpdate = async (previousProps: Props): Promise<void> => {
    if (this.props.ratingUnits.length <= 0 && !this.state.loading) {
      await this.loadCompanies();
    }
    if (
      previousProps.lineOfBusiness !== this.props.lineOfBusiness ||
      previousProps.state !== this.props.state
    ) {
      this.setRUsToShow();
    }
  };

  loadCompanies = async (): Promise<void> => {
    this.setState({
      loading: true,
    });
    await this.pullCompanies();
  };

  pullCompanies = async (): Promise<void> => {
    await this.props.getRatingUnitsController();

    this.setState({
      loading: false,
    });
  };

  getLineOfBusiness = (): string => {
    return this.props.lineOfBusiness === "CONDO" ||
      this.props.lineOfBusiness === "RENTERS"
      ? "HOMEOWNERS"
      : this.props.lineOfBusiness;
  };

  setRUsToShow = (): void => {
    const rusToShow = this.props.ratingUnits.filter(
      (ru: RatingUnit) =>
        ru.lineOfBusiness === this.getLineOfBusiness() &&
        ru.state === this.props.state
    );
    this.setState({
      rusToShow: rusToShow,

      selectedCompany: rusToShow.length > 0 ? rusToShow[0] : null,
    });
  };

  selectCompany = (unit: RatingUnit): void => {
    if (this.state.selectedCompany !== unit) {
      this.setState({
        selectedCompany: unit,
      });
    }
  };

  filter = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      filterText: e.target.value,
    });
  };

  getFilteredCompanies = (): RatingUnit[] => {
    return this.state.rusToShow.filter((unit) => {
      return (
        this.state.filterText === "" ||
        unit.name?.toLowerCase().includes(this.state.filterText.toLowerCase())
      );
    });
  };

  render = (): ReactElement => {
    return (
      <Container fluid className="p-0">
        <Row>
          <Col className="w-50">
            {this.state.rusToShow.length > 0 ? (
              <Row className="form-group has-search">
                <span className="fa fa-search form-control-search"></span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Filter"
                  onChange={this.filter}
                />
              </Row>
            ) : (
              ""
            )}

            {this.state.loading ? (
              <Spinner animation="border" className="loader" />
            ) : this.props.ratingUnits.length > 0 &&
              this.getFilteredCompanies().length > 0 ? (
              <CompanyView
                companies={this.getFilteredCompanies()}
                onClick={this.selectCompany}
                selectedRatingUnitId={this.state.selectedCompany?.id}
                showProvisioningInfo={false}
                showProvisionedCheckbox={false}
                onCheck={undefined}
              />
            ) : (
              <p className="text-center mt-4 text-muted">
                No companies are provisioned for {this.props.lineOfBusiness},{" "}
                {this.props.state}
              </p>
            )}
          </Col>

          <Col className="w-50">
            <div>
              {this.state.selectedCompany != null ? (
                <div>
                  <Row className="mt-39">
                    <div className="vertical-section">
                      <RatingUnitDetailsComponent
                        ratingUnit={this.state.selectedCompany}
                      />
                    </div>
                  </Row>
                  <Collapsible title="Company Questions" collapseType="outer">
                    <RatingUnitRatingDataComponent
                      ratingUnitId={
                        // The == is OK here, because null == undefined: true
                        this.state.selectedCompany?.id == null ||
                        isNaN(parseInt(this.state.selectedCompany.id, 10))
                          ? 0
                          : parseInt(this.state.selectedCompany.id, 10)
                      }
                      selectedLob={this.props.lineOfBusiness}
                    />
                  </Collapsible>

                  <Collapsible
                    title="Company Required Questions"
                    collapseType="outer"
                  >
                    <RequiredStaticQuestionsComponent
                      ratingUnitId={
                        // The == is OK here, because null == undefined: true
                        this.state.selectedCompany?.id == null ||
                        isNaN(parseInt(this.state.selectedCompany.id, 10))
                          ? 0
                          : parseInt(this.state.selectedCompany.id, 10)
                      }
                      lineOfBusiness={this.props.lineOfBusiness}
                    />
                  </Collapsible>
                </div>
              ) : (
                <div />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    );
  };
}

export default connector(CompanyQuestionPage);
