/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { RatingDataPacketState } from "./types";

export const initialState: RatingDataPacketState = {
  packets: [],
  editedPackets: [],
};
