/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { RatingUnitState } from "./types";

export const initialState: RatingUnitState = {
  ratingUnits: [],
  provisionedUnits: {},
};
