/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { InitialStateType } from "../../app/store/rootType";
import React, { Component, ReactElement } from "react";
import { Modal, Button } from "react-bootstrap";
import { updatePacket } from "../../app/api/ratingDataPacket";
import { connect, ConnectedProps } from "react-redux";
import { removeEditedPacketsController } from "../../app/store/ratingDataPacket/controller";

interface Props {
  saveModal: boolean;
  discardModal: boolean;
  closeModal: () => void;
  switchToView: () => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: InitialStateType) => ({
  editedRatingDataPackets: state.ratingDataPackets.editedPackets,
});

const mapDispatchToProps = {
  updatePacket,
  removeEditedPacketsController,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type FinalProps = PropsFromRedux & Props;

class confirmationModal extends Component<FinalProps, unknown> {
  closeModal = (): void => {
    this.props.closeModal();
  };

  saveRatingServicePackets = async (): Promise<void> => {
    await updatePacket(this.props.editedRatingDataPackets);
    await this.removeEditedPackets();
  };

  removeEditedPackets = async (): Promise<void> => {
    await this.props.removeEditedPacketsController();
    this.closeModal();
    this.props.switchToView();
  };

  render = (): ReactElement => {
    return (
      <Modal
        show={this.props.saveModal || this.props.discardModal}
        className="mt-5"
      >
        <div className="modal-dialog">
          {this.props.saveModal ? (
            <div className="modal-content">
              <div className="modal-header d-block text-center">
                <h5 className="modal-title">Confirmation!</h5>
              </div>
              <div className="modal-body">
                <div className="text-xs-center">
                  <div className="orangeCol">
                    Apply following pending changes?
                  </div>
                  <div className="m-2">
                    {this.props.editedRatingDataPackets.map((pack) => {
                      return (
                        <li key={pack.ratingData?.id}>
                          {pack.apiPath?.map((path, index) => {
                            return (
                              <span key={path?.id}>
                                {path?.name
                                  .replace(/([A-Z])/g, " $1")
                                  .replace("V1", "")
                                  .trim()}{" "}
                                <span className="orangeCol">-&gt;</span>
                              </span>
                            );
                          })}
                          {pack.apiProperty?.name}
                        </li>
                      );
                    })}
                  </div>
                  <div>
                    <Button
                      variant="primary"
                      onClick={this.saveRatingServicePackets}
                      className="v-btn float-left"
                    >
                      Yes, Apply Changes
                    </Button>

                    <Button
                      variant="secondary"
                      onClick={this.closeModal}
                      className="float-right"
                    >
                      No, Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="modal-content">
              <div className="modal-header d-block text-center">
                <h5 className="modal-title">Warning!</h5>
              </div>
              <div className="modal-body">
                <div className="text-xs-center">
                  <div className="orangeCol">
                    You are about to discard all pending changes. This action
                    cannot be undone.
                  </div>
                  <div className="m-2">
                    {this.props.editedRatingDataPackets.map((pack) => {
                      return (
                        <li key={pack.ratingData?.id}>
                          {pack.apiPath?.map((path, index) => {
                            return (
                              <span key={path?.id}>
                                {path?.name
                                  .replace(/([A-Z])/g, " $1")
                                  .replace("V1", "")
                                  .trim()}{" "}
                                <span className="orangeCol">-&gt;</span>
                              </span>
                            );
                          })}
                          {pack.apiProperty?.name}
                        </li>
                      );
                    })}
                  </div>
                  <div>
                    <Button
                      variant="primary"
                      onClick={this.removeEditedPackets}
                      className="v-btn float-left"
                    >
                      Yes, Discard All Changes
                    </Button>

                    <Button
                      variant="secondary"
                      onClick={this.closeModal}
                      className="float-right"
                    >
                      No, Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  };
}

export default connector(confirmationModal);
