/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { ReactElement } from "react";
import { History } from "history";
import { ConnectedRouter } from "connected-react-router/immutable";
import Routes from "./routes";

interface AppProps {
  history: History;
}

const App = ({ history }: AppProps): ReactElement => {
  return (
    <ConnectedRouter history={history}>
      <Routes />
    </ConnectedRouter>
  );
};

export default App;
